<template>
  <div class="">
    <el-card class="box-card" style="margin-bottom: 130px">
      <div class="itembox">
        <img src="../../assets/images/mycenter/psd.png" />
        <div class="content">
          <div class="tit font18">登录密码</div>
          <div class="text font14">
            建议您定期更换密码，设置安全性高的密码可以使帐号更安全
          </div>
          <div class="btn">
            <el-button type="primary" @click="xiugai()">修改</el-button>
          </div>
        </div>
      </div>
      <div class="itembox">
        <img src="../../assets/images/mycenter/psd.png" />
        <div class="content">
          <div class="tit font18">
            安全手机 <span>{{ getPhone(userInfo.phone) }}</span>
          </div>
          <div class="text font14">
            安全手机可以用于登录帐号，重置密码或其他安全验证
          </div>
          <div class="btn">
            <el-button type="primary" @click="genghuan()">更换</el-button>
          </div>
        </div>
      </div>
      <div class="wenxin font16">
        <div class="title">温馨提示</div>
        <div class="text-t">
          •
          注意防范进入钓鱼网站，不要轻信各种即时通讯工具发送的商品或支付链接，谨防网购诈骗
        </div>
        <div class="text-t">
          •
          Freemen不会以订单异常、系统升级为由要求您点击任何网址链接进行退款操作。
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import Bus from "../../common/bus.js";

export default {
  data() {
    return {
      userInfo: {},
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        this.userInfo = res.data.user;
      });
    },
    getPhone(num) {
      let phone = "";
      if (num) {
        let first = num.slice(0, 3);
        let content = "****";
        let last = num.slice(7, 11);
        phone = first + content + last;
      }
      return phone;
    },
    xiugai() {
      Bus.$emit("psdwordstuts", {
        show: true,
        config: this.userInfo,
      });
    },
    genghuan() {
      Bus.$emit("phonestuts", {
        show: true,
        config: this.userInfo,
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2) !important;
}
/deep/.el-card__body {
  padding: 30px 30px 50px 30px;
}
.itembox {
  display: flex;
  border: 1px solid #f3f3f3;
  padding: 40px;
  margin-bottom: 15px;
  img {
    width: 20px;
    height: 22px;
    margin-right: 20px;
  }
  .content {
    .tit {
      color: #111111;
      span {
        margin-left: 15px;
      }
    }
    .text {
      color: #999999;
      margin-top: 13px;
    }
    .btn {
      margin-top: 30px;
      /deep/ .el-button {
        border-radius: 0px;
        width: 140px;
        height: 36px;
        background-color: #00bcff;
        border: none;
        color: #ffffff;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;
      }
    }
  }
}
.wenxin {
  .title {
    color: #ff4444;
    margin: 30px 0 20px 0;
  }
  .text-t {
    color: #999999;
    margin-bottom: 5px;
  }
}
</style>
